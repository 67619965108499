































































































import { Vue, Component } from 'vue-property-decorator'
import DashboardService from '@/services/Dashboard.service'
import { DashboardUser, Role } from '@/interfaces/dashboard'
import * as EmailValidator from 'email-validator'

@Component({})
export default class NovoAcessoPage extends Vue {
  private dashService = new DashboardService();

  form: DashboardUser = {
    id: '',
    roles: '',
    _roles: [],
    email: ''
  } as DashboardUser

  Role = Role

  state = {
    loading: false,
    error: ''
  }

  async mounted() {
    this.dashService.findUserById(this.$route.params.id)
      .then((user) => {
        const rolesAsSelectOption = (user.roles as unknown as string[]).map(role => {
          return {
            label: Role[role],
            value: role
          }
        })

        user._roles = rolesAsSelectOption
        this.form = user
      })
  }

  async onSubmit() {
    const isValid = this.validate(this.form)

    if (!isValid) return

    this.state.loading = true

    const form: any = { ...this.form }
    form.roles = form._roles.map(r => r.value).join(',')
    delete form._roles
    this.dashService
      .updateDashboardUser(form)
      .then(() => {
        alert('Acesso editado com sucesso.')
        return this.$router.push('/configuracoes/acessos')
      })
      .catch((request) => {
        if (request.response?.data?.message) {
          this.state.error = request.response.data.message
        }
      })
      .then(() => {
        this.state.loading = false
      })
  }

  validate(form: Partial<DashboardUser>) {
    if (!EmailValidator.validate(form.email as string)) {
      this.state.error = 'Email inválido.'
      return false
    }

    if (!form._roles?.length) {
      this.state.error = 'Papel inválido.'
      return false
    }

    return true
  }

  onDeleteAccess() {
    const result = window.confirm('Tem certeza? Esta ação é irreversível.')

    if (result && this.form.id) {
      this.dashService
        .deleteDashboardUser(this.form.id)
        .then((response) => {
          const { rows_affected } = response

          if (rows_affected > 0) {
            alert(`Usuário ${this.form.email} deletado com sucesso.`)
          } else {
            alert('Erro ao remover acesso. Por favor contate o suporte.')
          }

          this.$router.push('/configuracoes/acessos')
        })
        .catch(request => {
          console.log(request)
          alert('Erro ao remover acesso. Por favor contate o suporte.')
        })
    }
  }
}

