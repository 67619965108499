























































































import { Vue, Component } from 'vue-property-decorator'
import DashboardService from '@/services/Dashboard.service'
import { DashboardUser, Role } from '@/interfaces/dashboard'
import * as EmailValidator from 'email-validator'

@Component({})
export default class NovoAcessoPage extends Vue {
  private dashService = new DashboardService();

  form: DashboardUser = {
    _roles: [] as Role[],
    email: ''
  } as DashboardUser

  Role = Role

  state = {
    loading: false,
    error: ''
  }

  async onSubmit() {
    const isValid = this.validate(this.form)

    if (!isValid) return

    this.state.loading = true

    const form: any = { ...this.form }
    form.roles = form._roles.map(r => r.value).join(',')
    delete form._roles

    this.dashService
      .saveDashboardUser(form)
      .then(() => {
        alert('Novo acesso cadastrado.')
        return this.$router.push('/configuracoes/acessos')
      })
      .catch((request) => {
        if (request.response?.data?.message) {
          this.state.error = request.response.data.message
        }
      })
      .then(() => {
        this.state.loading = false
      })
  }

  validate(form: Partial<DashboardUser>) {
    if (!EmailValidator.validate(form.email as string)) {
      this.state.error = 'Email inválido.'
      return false
    }

    if (!form._roles?.length) {
      this.state.error = 'Papel inválido.'
      return false
    }

    return true
  }
}

