



























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import DashboardService from '@/services/Dashboard.service'
import { DashboardUser, Role } from '@/interfaces/dashboard'
import Model, { PageOptions, Pagination as IPagination } from '@/lib/prest-query-builder'
import PrestPagination from '@/components/PrestPagination.vue'
import { Watch } from 'vue-property-decorator'
const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

interface FilterOptions {
  roles: Role | null;
  email: string | null;
}

@Component({
  components: {
    PrestPagination
  }
})
export default class Users extends Vue {
  private dashService = new DashboardService();

  dashboardUsers: DashboardUser[] = []

  usersModel!: Model<DashboardUser>

  filter: FilterOptions = {
    roles: null,
    email: null
  }

  Role = Role

  options = {
    _page: 1,
    _page_size: 20
  }

  state = {
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  pagination: IPagination = {} as IPagination

  pageOptions: PageOptions = {
    page: 1,
    pageSize: 20
  }

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  loadUsers() {
    this.usersModel
      .pagination(this.pageOptions)
      .run()
      .then((response: any) => {
        this.dashboardUsers = response.items.map((user: DashboardUser) => {
          user._roles = user.roles.split(',') as Role[]
          return user
        })

        this.pagination = response.pagination
        this.state.loading = false
      })
  }

  onToPage(n) {
    this.$set(this.pageOptions, 'page', n)
    this.loadUsers()
  }

  onFilterReset() {
    this.filter = { roles: null, email: null }
    this.onFilterSubmit()
  }

  onFilterSubmit() {
    this.state.loading = true

    const opts = {
      _page: 1,
      _page_size: 20,
      ...this.filter
    }

    this.dashService.allUsers(opts)
      .then(users => {
        this.dashboardUsers = users
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        this.state.loading = false
      })
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.usersModel = new Model<DashboardUser>({
        url: `${VUE_APP_API_PREST_NEW}/Dashboard/public/users`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.loadUsers()
    }
  }
}

